import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../sharedServices/common.service';
import { CanActivate, Router } from '@angular/router';
import { timeout } from 'rxjs/operators';


@Component({
  selector: 'forgotpassword',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  forgotForm: FormGroup;
  submitted = false;
  forgotFailureMsg;
  forgotSuccessMsg;
  showSpinner = false;

  constructor(private formBuilder: FormBuilder, private common: CommonService,private route: Router) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotForm.controls; }

  onSubmit(value: any) {
    value = JSON.stringify(value);
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }
    this.common.forgotpassword(value).pipe(timeout(5000)
    ).subscribe( data => {
        this.forgotSuccessMsg = 'Please check your inbox for new password!';
        //this.route.navigate(['/login']);
        this.showSpinner = false;
      }, error => { this.forgotFailureMsg = (error.error !== undefined) ? error.statusText : 'Sorry, something went wrong! please try after sometimes.';
      if(error.status === 404) {
         this.forgotFailureMsg = 'Failed to fetch an account'; 
        
      }
      
    });

  }

}
