import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable,BehaviorSubject,of, interval} from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonService } from '../../sharedServices/common.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class MainNavComponent {

  routerLinkActive: string | string[]

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
    profileData;
    notifications:any;
    count;
    message:string= 'secondChild';
  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private common : CommonService) {}
  ngOnInit() {
    this.profileData = JSON.parse(localStorage.getItem("profiledata"));
    //console.log(this.profileData);
    this.getAsync2(this.profileData.image);
    this.common.getNotifications(this.profileData.hubId).subscribe((data) => {
      this.notifications = data;
      this.count = data.length;
    });
    this.common.currentMessage.subscribe(message=> this.message = message)
  }

  onLogOut() {
    this.router.navigate(['/login']);
    localStorage.clear();
    sessionStorage.clear();
    this.delete_cookie('_isLoggedIn');
  }
  readNotification(id){
    this.common.viewNotification(id).subscribe(
      (data) => {
          console.log(data);
      },
      (error)=>{
        console.error(error);
      });
      this.common.getNotifications(this.profileData.hubId).subscribe((data) => {
        this.notifications = data;
        this.count = data.length;
        this.router.navigate(['/dashboard/announcement']);
      });
  }

  delete_cookie(name) {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  private getAsync2(msg:any){
    this.common.changeURL(msg);
  } 

}
