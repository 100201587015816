import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
// import { Router } from '@angular/router';
import { Router } from '@angular/router';
import { Sessioncheck } from './utility/index'
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fianz';
  loggedIn: boolean;
  constructor (private sessioncheck: Sessioncheck,private router: Router, private bnIdle: BnNgIdleService) {
    this.bnIdle.startWatching(900).subscribe((res) => {
      if(res) {
        this.onLogOut();
      }
    })
  }

  ngOnInit () {
    this.sessioncheck.checkSessionStatus ();
    this.sessioncheck.loggedIn
      .pipe (delay(0))
      .subscribe ((data) => {
        this.loggedIn = data;
      });
  }

  onLogOut() {
    this.router.navigate(['/login']);
    localStorage.clear();
    sessionStorage.clear();
    this.delete_cookie('_isLoggedIn');
  }
  delete_cookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
