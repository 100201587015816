import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,  } from '@angular/forms';
import { CommonService } from '../../sharedServices/common.service';
import { MustMatch } from './_helpers/must-match.validator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent implements OnInit {
  signUp: FormGroup;
  submitted = false;
  signUpSuccessMsg = null;
  signUpFailureMsg = null;
  countries: {};
  regions: {};
  cities: {};
  suburbs: {};
  public imagePath;
  imgURL: any;
  public message: string;
  showSpinner = false;
  constructor(private formBuilder: FormBuilder, private common: CommonService,private router: Router) {}

  ngOnInit() {
    this.signUp = this.formBuilder.group({
      channelName: ['', Validators.required],
      address: ['', Validators.required],
      region: ['', Validators.required],
      city: ['', Validators.required],
      // country: ['New Zealand', Validators.required],
      suburb: ['', Validators.required],
      areaCode: ['', Validators.required],
      description: ['',Validators.required],
      website: [''],
      personTwoFullName: [''],
      personTwoFullPhone: [''],
      personTwoFullEmail: [''],
      profileImage: [''],
      base64Image: [''],
      personOneFullName: ['', Validators.required],
      personOnePhone: ['', Validators.required, ],
      personOneEmail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.common.getCountries().subscribe(
      data => {
              this.countries = data
              this.regions = this.countries[0].regionsList;
            }
      );
  }


  onChangeRegion(regionId: number) {
    if (regionId) {
           let regions_arr:any;
           regions_arr = this.regions;
           var result = regions_arr.filter(function (region) { return region.regionId == regionId });
           this.cities = result[0].citiesList;
    } else {
      this.cities = null;
    }
  }
  onChangeCity(cityId: number) {
    if (cityId) {
      let cities_arr:any;
      cities_arr = this.cities
      var result = cities_arr.filter(function (city) { return city.cityId == cityId });
          this.suburbs = result[0].suburbsList;
    } else {
      this.cities = null;
    }
  }
  _keyPress(event: any) {
      const pattern = /[0-9]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
          event.preventDefault();
      }
  }


  // convenience getter for easy access to form fields
  get f() { return this.signUp.controls; }

  onSubmit(value: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signUp.invalid) {
        console.error('Form is invalid');
        // return;
    }

    // display form values on success
    this.common.signUp(value).subscribe( data => {
      // console.log(data);
      if (data) {
        
      }
    },
    error =>{ this.signUpFailureMsg = (error.error !== undefined) ? error.error : 'Unknown';
              this.signUpSuccessMsg='';
              if(error.status==201){
                this.signUpFailureMsg = '';
               this.signUpSuccessMsg = "THANK YOU! An email has been sent to your email address. Please check the inbox. <a href='/login'>Click Here</a>";
               this.onReset();
               this.imgURL = "";
               //this.router.navigate(['/login']);
               this.showSpinner = false;
      }}
  );
  }

  preview(files) {
    if (files.length === 0)
    return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    var read = new FileReader();
     read.onload = this._handleReaderLoaded.bind(this);
     read.readAsBinaryString(files[0]);
  }
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.signUp.value.base64Image = btoa(binaryString);  // Converting binary string data.
    // console.log(this.signUp );
}


  onReset() {
    this.submitted = false;
    this.signUp.reset();
  }
}
