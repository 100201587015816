import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AngularMaterial } from './material-module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { from } from 'rxjs';

import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { LoginComponent, SignUpComponent, ForgotComponent, TermsAndConditions, PrivacyPolicy  } from './Authentication/index';

import { FooterComponent } from './common/index'

import { CommonService } from './sharedServices/common.service';

import { Sessioncheck, LoginAuth , AuthenticationInterceptor  } from './utility/index';

// import { CommonService } from './sharedServices/apiservices/common.service';

import { LayoutModule } from '@angular/cdk/layout';

import { BnNgIdleService } from 'bn-ng-idle';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterial,
    ReactiveFormsModule
  ],
  declarations: [
    AppComponent, 
    LoginComponent, 
    FooterComponent, 
    SignUpComponent, 
    ForgotComponent,
    TermsAndConditions,
    PrivacyPolicy
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    CommonService, Sessioncheck, LoginAuth, BnNgIdleService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
