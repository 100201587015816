import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Sessioncheck {
    loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private route: Router) {
        //
    }

    checkSessionStatus() {
        let tempStatus: boolean;

        if (document.cookie.indexOf(`_isLoggedIn`) === -1) {
            tempStatus = false;
        } else {
            tempStatus = true;
            // localStorage.clear();
        }
        this.checknavigation(tempStatus);
        this.loggedIn.next(tempStatus);
    }

    checknavigation(value: boolean) {
        if (!value) {
            this.route.navigate(['/login']);
        }
    }

    checksessionExpiry() {
        let sessionstatus: boolean;
        if (document.cookie.indexOf(`_isLoggedIn`) !== -1) {
            sessionstatus = true;
        } else {
            sessionstatus = false;
            localStorage.clear();
            this.route.navigate(['/login']);
        }
        return sessionstatus;
    }



}