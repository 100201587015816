import { Component, OnInit } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../sharedServices/common.service';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  authFailedMsg = '';
  showSpinner = false;

  constructor(private formBuilder: FormBuilder, private common: CommonService, private route: Router) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      hubId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  onLoginSubmit(value: any) {
    this.common.login(value).pipe(timeout(5000))
    .subscribe(
      data => {
        console.log(data);
        if (data) {
          localStorage.setItem('profiledata', JSON.stringify(data));
          this.route.navigate(['/dashboard']);
          // this.showSpinner = false;
        }
      },
      error => { this.authFailedMsg = (error.error !== undefined) ? error.statusText : 'Sorry, something went wrong! please try after sometimes.';
      if(error.status == 200){
        this.authFailedMsg = ' Sorry, something went wrong! please try after sometimes.';
       }
       this.showSpinner = false;
      }
      
    );
  }



}
