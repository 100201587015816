import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Sessioncheck } from '../models/session.model'

@Injectable()
export class LoginAuth implements CanActivate {
    loggedIn: boolean;

    constructor (private route: Router, private sessionCheck: Sessioncheck) {
        //
    }

    canActivate () {
        this.sessionCheck.loggedIn.subscribe((data) => {
            this.loggedIn = data;
        });

        if (!this.loggedIn) {
            return !this.loggedIn;
        } else {
            this.route.navigate (['/dashboard']);
            return this.loggedIn;
        }
    }
}