import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginAuth } from './utility/index'

import { LoginComponent, SignUpComponent, ForgotComponent, TermsAndConditions, PrivacyPolicy  } from './Authentication/index';

const routes: Routes = [
  
  { path: 'login', component: LoginComponent , canActivate: [ LoginAuth ] },
  { path: '', component: LoginComponent , canActivate: [ LoginAuth ] },
  { path: '',   loadChildren: () => import('./dashboard/dashboard.component.module').then(m => m.DashboardModule)  },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotComponent},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'terms-and-conditions', component: TermsAndConditions },
  { path: 'privacy-policy', component: PrivacyPolicy }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }



