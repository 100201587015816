import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpHeaders, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, filter, take, switchMap, catchError, sample } from 'rxjs/operators';

import { Sessioncheck } from '../models/session.model'

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(private http: HttpClient, private sessioncheck: Sessioncheck) {
        //
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('hubsignin')) {
            sessionStorage.clear();
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                 'Accept' : 'application/json',
            });
            const updatedRequest = request.clone({ headers });
            return next.handle(updatedRequest)
                .pipe(
                    map((event: any) => {
                        if (event instanceof HttpResponse) {
                            document.cookie = `_isLoggedIn= true;Path=/;max-age= 2000`;

                            // set session cookie here with variable name ' _isLoggedIn' ..
                            return event;
                        }
                    })
                );
        } else {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                 'Accept' : 'application/json',
            });
            const updatedRequest = request.clone({ headers });
            // session expiry chech function has to come here...
            // sample code
            //  const sessionflag = this.sessioncheck.checksessionExpiry();
            //   if(sessionflag) {
            return next.handle(updatedRequest);
            //   } else {
            //     information alert 'session expired'
            // }
        }
    }
}
