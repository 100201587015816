import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { JsonPipe } from '@angular/common';
const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Accept': 'application/json',
    })
  };
@Injectable()
export class CommonService {


  /*live Api URL & Auth key*/
  prifixUri='http://13.238.17.218:8080/apiman-gateway/fianz/nz-islam-prod/1.0/';
  authkey='f71c7571-9044-41a3-9169-cfc6bca4bccd';

  /*dev Api URL & Auth key*/
  // prifixUri = 'http://13.238.17.218:8080/apiman-gateway/fianz-dev/islam-in-newzealand-nossl/1.0/';
  // authkey = 'ad42a312-5bb9-487d-9d5e-d5f7c096e965';

  public data: BehaviorSubject<string> = new BehaviorSubject('First test');
  currentMessage = this.data.asObservable();
  dahboardSwitch: BehaviorSubject<boolean> = new BehaviorSubject( true );


    constructor(private http: HttpClient) {

    }

    forgotpassword(value: any) {
        return this.http.post(this.prifixUri+'users/forgotpassword?apikey='+this.authkey,value,httpOptions)
        .pipe(
            map((response: any) => {
            // console.log(response);
            return response;
            }),
        );
    }

    login(value) {
      return this.http.post(`${this.prifixUri}users/hubsignin?apikey=${this.authkey}`,value)
      .pipe(
          map((response: any) => {
              return response;
          }),
      );
  }

    signUp(value: any) {

      let signUp = {
        hubName : value.channelName,
        address : value.address,
        areacode : value.areaCode,
        description : value.description,
        suburbId : value.suburb,
        webSite : value.website,
        image : value.base64Image,
        firstAuthorisedFullName : value.personOneFullName,
        firstAuthorisedEmail : value.personOneEmail,
        firstAuthorisedPhone : value.personOnePhone,
        password : value.confirmPassword,
        secondAuthorisedEmail: value.personTwoFullEmail,
        secondAuthorisedFullName: value.personTwoFullName,
        secondAuthorisedPhone: value.personTwoFullPhone,
        device : "Desktop"
      }

      let data = JSON.stringify(signUp);
      data = JSON.parse(data);
      console.log(data);
      return this.http.post(this.prifixUri+'users/hubsignup?apikey='+this.authkey, data, httpOptions).pipe(
        map((response: any) => {
          // console.log(response);
          return response;
        })
      );
    }
    update(data: any) {
      return this.http.patch(this.prifixUri+'users/secured/changehubprofile?apikey='+this.authkey, data, httpOptions).pipe(
        map((response: any) => {
          // console.log(response);
          return response;
        })
      );
    }
    getCountries() {
      return this.http.get(this.prifixUri+'countries?apikey='+this.authkey, httpOptions).pipe(
        map((response: any) => {
          return response;
        })
      );
    }

    getTotalSubscribers(hubId) {
      var url = this.prifixUri+'secured/subscribers/countbyhub/'+hubId+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
        map((response: any) => {
          return response;
        })
      );
    }

    getMonthlySubscribers(hubId,month) {
      var url = this.prifixUri+'secured/subscribers/monthlysubscriberbyhub/'+hubId+'/'+month+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    getYearlySubscribers(hubId,year) {
      var url = this.prifixUri+'secured/subscribers/yearlysubscriberbyhub/'+hubId+'/'+year+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    getHubReport(hubId) {
      var url = this.prifixUri+'secured/subscribers/hubreport/'+hubId+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    getNotifications(hubId) {
          var url = this.prifixUri+'secured/notifications/findnewby/'+hubId+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    viewNotification(id) {
          var url = this.prifixUri+'secured/notifications/'+id+'?apikey='+this.authkey;
          return this.http.put(url, httpOptions).pipe(
            map((response: any) => {
              return response;
          })
      );
    }
    submitFeedback(value: any) {
      // console.log(value)
      value = JSON.stringify(value);
      // console.log(value)

      return this.http.post(this.prifixUri+'secured/feedbacks/submit?apikey='+this.authkey, value, httpOptions).pipe(
        map((response: any) => {
          return response;
        })
      );
    }

    getFeedback(hubId) {
      var url = this.prifixUri+'secured/feedbacks/findby/hub/'+hubId+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    viewFeedback(id) {
      var url = this.prifixUri+'secured/feedbacks/viewby/'+id+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    publishPrayer(value) {
      var url = this.prifixUri+'secured/salattimes/publish?apikey='+this.authkey;
          return this.http.post(url,value, httpOptions).pipe(
            map((response: any) => {
              // console.log(response)
              return response;
            })
      );
    }
    publishAllPrayer(value) {
      var url = this.prifixUri+'secured/salattimes/publishall?apikey='+this.authkey;
          return this.http.post(url,value, httpOptions).pipe(
            map((response: any) => {
              // console.log(response)
              return response;
            })
      );
    }
    getPrayer(hubId) {
      var url = this.prifixUri+'secured/salattimes/listby/'+hubId+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    getSuburb(id) {
      var url = this.prifixUri+'suburbs/'+id+'/?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    publishAnnouncement(value) {
      var url = this.prifixUri+'secured/events/saveandpublish?apikey='+this.authkey;
          return this.http.post(url,value, httpOptions).pipe(
            map((response: any) => {
              // console.log(response)
              return response;
            })
      );
    }
    scheduleAnnouncement(value) {
      var url = this.prifixUri+'secured/events/schedule/publish?apikey='+this.authkey;
          return this.http.post(url,value, httpOptions).pipe(
            map((response: any) => {
              // console.log(response)
              return response;
            })
      );
    }
    getAdminAnnouncement() {
      var url = this.prifixUri+'secured/events/listby/admin/hub?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    getAnnouncement(hubId) {
      var url = this.prifixUri+'secured/events/listby/'+hubId+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    viewAnnouncement(id) {
      var url = this.prifixUri+'secured/events/view/'+id+'?apikey='+this.authkey;
          return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    deleteAnnouncement(id) {
      var url = this.prifixUri+'secured/events/remove/'+id+'?apikey='+this.authkey;
          return this.http.delete(url, httpOptions).pipe(
            map((response: any) => {
              return response;
            })
      );
    }
    changeURL(message: string) {
      this.data.next(message);
      // console.log(this.data.value);
    }
}

